import React from "react";
import FormInput from "./FormInput";
import FormRowSelect from "./FormRowSelect";
import { useGlobalContext } from "../context/UserContext";

const AutoManual = () => {
  const { bankName, handleChange, paymentReference } = useGlobalContext();
  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    handleChange({ name, value });
  };
  return (
    <div className="my-4">
      <div className="bg-green-100 text-left p-2">
        <p className="text-centertext-lg font-bold text-green-600 mb-3 capitalize text-center">
          This method fund your wallet instantly. only make payments to the
          provided account numbers below
        </p>
        <div className="mb-2">
          <p>
            OPAY TO OPAY- 8067657841 (Onisabi Abdullahi) <br />
            Use OPAY for OPAY 2 OPAY funding only and input the "Transaction
            No."
          </p>
          {/* <p>MONNIEPOINT TO MONNIEPOINT- coming soon(Data Reloaded)</p> */}
        </div>
        <p className="capitalize  text-red-500">
          NOTE: minimum funding is #100 and do not make payment to non-listed
          account numbers here
        </p>
      </div>
      <FormRowSelect
        list={["select", "OPAY_8067657841"]}
        labelText="select from the available banks"
        name="bankName"
        handleChange={handleInputChange}
        value={bankName}
      />
      <FormInput
        type="text"
        labelText="Enter Transaction Id/Reference"
        name="paymentReference"
        handleChange={handleInputChange}
        value={paymentReference}
        className="text-left py-2"
      />
      <FormInput
        type="number"
        labelText="Enter amount paid"
        name="amountPaid"
        handleChange={handleInputChange}
        className="text-left py-2"
      />
    </div>
  );
};

export default AutoManual;
